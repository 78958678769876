/* eslint-disable no-unused-vars */

import { I_Hotspot, I_MarketingScriptChat } from '@containers/Home/types';
import { nanoid } from '@reduxjs/toolkit';
import { notification, message } from 'antd';
import { colord, extend } from 'colord';
import namesPlugin from 'colord/plugins/names';

extend([namesPlugin]);

const PHARSE_SECRET_PANOEE = 'panoee-platform';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

function hasKey<O extends object>(
  obj: O,
  key: string | number | symbol,
): key is keyof O {
  return key in obj;
}

export const openNotificationWithIcon = (
  type: NotificationType,
  title: string,
  description?: string,
  duration?: number,
  key?: string,
) => {
  notification[type]({
    ...(key ? { key } : {}),
    message: title,
    description,
    duration: (duration ?? 5) * 1000,
  });
};

/**
 * @param {number} input: number
 * @return number formatted, 100000 => 100,000
 */
export const formatNumber = (number: number): string => {
  const value = `${number}`;
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
};

/**
 * @param func function debounced
 * @param waitFor time debounce
 */
export const debounce = <F extends (...args: any) => any>(
  func: F,
  waitFor: number,
) => {
  const timeout = 0;

  const debounced = (...args: any) => {
    clearTimeout(timeout);
    setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const isServer = typeof window === 'undefined';

export const toggleItemArrs = <T extends string | number>(
  arrs: T[],
  item: T,
) => {
  const listXor: T[] = arrs.some(el => el === item)
    ? arrs.filter(el => el !== item)
    : [...arrs, item];
  return listXor;
};

export const convertLinkVideoToPlayable = src => {
  let urlConverted = src;
  const linkYoutube = src.match(
    /(?:youtube\.com|youtu\.be|youtube-nocookie\.com)\/(?:watch\?(?:.*&)?v=|v\/|u\/|embed\/?)?(videoseries\?list=(?:.*)|[\w-]{11}|\?listType=(?:.*)&list=(?:.*))(?:.*)/i,
  );
  const linkVimeo = src.match(/^.+vimeo.com\/(?:\/)?([\d]+)(.*)?/);
  if (linkYoutube) {
    const videoId = encodeURIComponent(linkYoutube[1]);
    urlConverted = `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&autoplay=1&controls=0&amp;showinfo=0&&amp;modestbranding=1`;
  }
  if (linkVimeo) {
    const videoId = encodeURIComponent(linkVimeo[1]);
    urlConverted = `https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&autopause=0&mute=1&background=1`;
  }
  return urlConverted;
};

export const filterHotspotVisible = (hotspot: I_Hotspot) => {
  let visible = false;
  switch (hotspot.type) {
    case 'article':
      visible = !!hotspot.config?.article?.post_id;
      break;
    case 'product':
      visible = !!hotspot.itemPrd?.id;
      break;
    case 'link':
      visible = !!hotspot.config?.link?.url;
      break;
    case 'image':
      visible = !!hotspot.config?.image?.galleries?.length;
      break;
    case 'point':
      visible = true;
      // visible = !!hotspot.config?.point?.target_scene_id;
      break;
    case 'chevron':
      visible = !!hotspot.config?.chevron?.target_scene_id;
      break;
    case 'video':
      visible = !!hotspot.config?.video?.url;
      break;
    case 'media':
      visible =
        (hotspot.config?.media.type !== 'media-text' &&
          !!hotspot.config?.media?.media?.src) ||
        (hotspot.config?.media.type === 'media-text' &&
          !!hotspot.config?.media?.content);
      break;
    case 'sound':
      visible =
        !!hotspot.config?.sound?.file?.src || !!hotspot.config?.sound?.url;
      break;
    case 'compact':
      visible = !!hotspot.config?.compact?.length;
      break;
    case 'instructor':
      visible = !!hotspot.config?.instructor?.media?.src;
      break;
    default:
      break;
  }
  return visible;
};

export const separateColorAndTransparency = (color: string) => {
  const alpha = colord(color).alpha();
  const rgba = colord(color).toRgb();
  const colorWithoutTransparency = colord(rgba).alpha(1).toHex();
  return { color: colorWithoutTransparency, alpha };
};

export const convertScriptChats = (scriptChats: I_MarketingScriptChat[]) => {
  const htmls = scriptChats.reduce((result, chat) => result + chat.html, '');
  const scripts = scriptChats.reduce(
    (result, chat) => result + chat.script,
    '',
  );
  return { htmls, scripts };
};

export const onDisabledReactDevtoolOnProduction = () => {
  if (!isServer && process.env.NODE_ENV === 'production') {
    // Ensure the React Developer Tools global hook exists
    const _window = window as any;
    if (typeof _window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'object') return;

    // Replace all global hook properties with a no-op function or a null value
    Object.keys(_window.__REACT_DEVTOOLS_GLOBAL_HOOK__).forEach(prop => {
      if (prop === 'renderers') {
        // prevents console error when dev tools try to iterate of renderers
        _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
      } else {
        _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
          typeof _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function'
            ? Function.prototype
            : null;
      }
    });
  }
};

export const encryptText = (mess: string) => {
  const textToChars = (text: string) =>
    text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => `0${Number(n).toString(16)}`.substr(-2);
  const applySaltToChar = code =>
    // eslint-disable-next-line no-bitwise
    textToChars(PHARSE_SECRET_PANOEE).reduce((a, b) => a ^ b, code);

  return mess
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
};

export const decryptText = (data: string) => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code =>
    // eslint-disable-next-line no-bitwise
    textToChars(PHARSE_SECRET_PANOEE).reduce((a, b) => a ^ b, code);
  return data
    .match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
};

export const openMessageWithIcon = (
  type: string,
  content: any,
  duration?: number,
) => {
  // key && message.config({ key });
  if (hasKey(message, type))
    message[type](content, duration && duration > 1 ? duration : 2);
};

/**
 * @return id random
 */
export const generateRandomID = () => nanoid();
